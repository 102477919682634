import {
  //コインロッカー一覧
  FETCH_LOCATION_COIN_LOCKERS_REQUEST,
  //Location - 詳細
  FETCH_LOCATION_DETAIL_REQUEST,
  //施設一覧
  FETCH_LOCATION_FACILITIES_REQUEST,
  //人気預かりエリア一覧
  FETCH_LOCATION_POPULAR_AREAS_REQUEST,
  //おすすめ預かり場所一覧
  FETCH_LOCATION_RECOMMENDED_SPACES_REQUEST,
  //駅一覧
  FETCH_LOCATION_STATIONS_REQUEST,
  SET_LOCATION_COIN_LOCKERS_DATA,
  SET_LOCATION_DETAIL_DATA,
  SET_LOCATION_FACILITIES_DATA,
  SET_LOCATION_POPULAR_AREAS_DATA,
  SET_LOCATION_RECOMMENDED_SPACES_DATA,
  SET_LOCATION_STATIONS_DATA,
} from './types'

import {
  LocationCoinLocker,
  LocationDetail,
  LocationFacilities,
  LocationPopularAreas,
  // LocationRecommendedSpaces,
  LocationRecommendedSpace,
  LocationStations,
} from './models'

//Location 詳細 - fetch
export const fetchLocationDetailRequest = (
  id: string,
  type: string,
  country_code: string,
  slug?: string
): Readonly<{
  type: 'FETCH_LOCATION_DETAIL_REQUEST'
  payload: {
    params: {
      id: string
      type: string
      country_code: string
      slug?: string
    }
  }
}> => {
  return {
    type: FETCH_LOCATION_DETAIL_REQUEST,
    payload: {
      params: {
        id: id,
        type: type,
        country_code: country_code,
        slug: slug,
      },
    },
  }
}

//Location 詳細 - set
export const setLocationDetailData = (
  result: LocationDetail
): Readonly<{
  type: 'SET_LOCATION_DETAIL_DATA'
  payload: {
    locationDetail: {
      result: LocationDetail
    }
  }
}> => {
  return {
    type: SET_LOCATION_DETAIL_DATA,
    payload: {
      locationDetail: { result },
    },
  }
}

//--------------------------

//人気預かりエリア一覧 - fetch
export const fetchLocationPopularAreasRequest = (
  latitude: string,
  longitude: string,
  type: string,
  country_code: string,
  prefecture_id: number,
  city_id: number
): Readonly<{
  type: 'FETCH_LOCATION_POPULAR_AREAS_REQUEST'
  payload: {
    params: {
      latitude: string
      longitude: string
      type: string
      country_code: string
      prefecture_id: number
      city_id: number
    }
  }
}> => {
  return {
    type: FETCH_LOCATION_POPULAR_AREAS_REQUEST,
    payload: {
      params: {
        latitude: latitude,
        longitude: longitude,
        type: type,
        country_code: country_code,
        prefecture_id: prefecture_id,
        city_id: city_id,
      },
    },
  }
}

//人気預かりエリア一覧 - set
export const setLocationPopularAreasData = (
  result: LocationPopularAreas
): Readonly<{
  type: 'SET_LOCATION_POPULAR_AREAS_DATA'
  payload: {
    locationPopularAreas: {
      result: LocationPopularAreas
    }
  }
}> => {
  return {
    type: SET_LOCATION_POPULAR_AREAS_DATA,
    payload: {
      locationPopularAreas: { result },
    },
  }
}

//--------------------------

//おすすめ預かり場所一覧 - fetch
export const fetchLocationRecommendedSpacesRequest = (
  latitude: string,
  longitude: string,
  country_code: string,
  prefecture_id: number,
  country_id: number,
  page: number,
  page_size: number,
  language: string,
  location_id: string
): Readonly<{
  type: 'FETCH_LOCATION_RECOMMENDED_SPACES_REQUEST'
  payload: {
    params: {
      latitude: string
      longitude: string
      country_code: string
      prefecture_id: number
      country_id: number
      page: number
      page_size: number
      language: string
      location_id: string
    }
  }
}> => {
  return {
    type: FETCH_LOCATION_RECOMMENDED_SPACES_REQUEST,
    payload: {
      params: {
        latitude: latitude,
        longitude: longitude,
        country_code: country_code,
        prefecture_id: prefecture_id,
        country_id: country_id,
        page: page,
        page_size: page_size,
        language: language,
        location_id: location_id,
      },
    },
  }
}

//おすすめ預かり場所一覧 - set
export const setLocationRecommendedSpacesData = (
  // result: LocationRecommendedSpaces
  result: {
    spaces: LocationRecommendedSpace[]
    total_count: number
  }
): Readonly<{
  type: 'SET_LOCATION_RECOMMENDED_SPACES_DATA'
  payload: {
    locationRecommendedSpaces: {
      // result: LocationRecommendedSpaces
      result: {
        spaces: LocationRecommendedSpace[]
        total_count: number
      }
    }
  }
}> => {
  return {
    type: SET_LOCATION_RECOMMENDED_SPACES_DATA,
    payload: {
      locationRecommendedSpaces: { result },
    },
  }
}

//--------------------------

//コインロッカー一覧 - fetch
export const fetchLocationCoinLockersRequest = (
  latitude: string,
  longitude: string,
  // prefecture_id: number,
  language: string
): Readonly<{
  type: 'FETCH_LOCATION_COIN_LOCKERS_REQUEST'
  payload: {
    params: {
      latitude: string
      longitude: string
      // prefecture_id: number
      language: string
    }
  }
}> => {
  return {
    type: FETCH_LOCATION_COIN_LOCKERS_REQUEST,
    payload: {
      params: {
        latitude: latitude,
        longitude: longitude,
        // prefecture_id: prefecture_id,
        language: language,
      },
    },
  }
}

//コインロッカー一覧 - set
export const setLocationCoinLockersData = (
  result: LocationCoinLocker[]
): Readonly<{
  type: 'SET_LOCATION_COIN_LOCKERS_DATA'
  payload: {
    locationCoinLockers: {
      result: LocationCoinLocker[]
    }
  }
}> => {
  return {
    type: SET_LOCATION_COIN_LOCKERS_DATA,
    payload: {
      locationCoinLockers: { result },
    },
  }
}

//--------------------------

//駅一覧 - fetch
export const fetchLocationStationsRequest = (
  country_code: string,
  prefecture_id: number,
  city_id: number
): Readonly<{
  type: 'FETCH_LOCATION_STATIONS_REQUEST'
  payload: {
    country_code: string
    prefecture_id: number
    city_id: number
  }
}> => {
  return {
    type: FETCH_LOCATION_STATIONS_REQUEST,
    payload: {
      country_code: country_code,
      prefecture_id: prefecture_id,
      city_id: city_id,
    },
  }
}

//駅一覧 - set
export const setLocationStationsData = (
  result: LocationStations
): Readonly<{
  type: 'SET_LOCATION_STATIONS_DATA'
  payload: {
    locationStations: {
      result: LocationStations
    }
  }
}> => {
  return {
    type: SET_LOCATION_STATIONS_DATA,
    payload: {
      locationStations: { result },
    },
  }
}

//--------------------------

//施設一覧 - fetch
export const fetchLocationFacilitiesRequest = (
  country_code: string,
  prefecture_id: number,
  city_id: number
): Readonly<{
  type: 'FETCH_LOCATION_FACILITIES_REQUEST'
  payload: {
    country_code: string
    prefecture_id: number
    city_id: number
  }
}> => {
  return {
    type: FETCH_LOCATION_FACILITIES_REQUEST,
    payload: {
      country_code: country_code,
      prefecture_id: prefecture_id,
      city_id: city_id,
    },
  }
}

//施設一覧 - set
export const setLocationFacilitiesData = (
  result: LocationFacilities
): Readonly<{
  type: 'SET_LOCATION_FACILITIES_DATA'
  payload: {
    locationFacilities: {
      result: LocationFacilities
    }
  }
}> => {
  return {
    type: SET_LOCATION_FACILITIES_DATA,
    payload: {
      locationFacilities: { result },
    },
  }
}
